import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'

// import SearchTabOne from '../samples/SearchTabOne'
// import SearchTabTwo from '../samples/SearchTabTwo'
// import SearchTabThree from '../samples/SearchTabThree'
// import SearchTabFour from '../samples/SearchTabFour'
// import SearchTabFive from '../samples/SearchTabFive'
// import SearchTabSix from '../samples/SearchTabSix'

import TabTownship from './TabTownship'
// import TabTpeMrt from './TabTpeMrt'
// import TabTpeMrtFuture from './TabTpeMrtFuture'
// //import TabTyhMrt from './TabTyhMrt'
// import TabTyhMrtFuture from './TabTyhMrtFuture'
//import TabTccMrtFuture from './TabTccMrtFuture'
//import TabKhcMrt from './TabKhcMrt'
import TabMrt from './TabMrt'

import {
  lines as tpemrtLines,
  stations as tpemrtStations,
} from '../data/tpemrt'

import {
  lines as tpemrtFutureLines,
  stations as tpemrtFutureStations,
} from '../data/tpemrt-future'

import {
  lines as tyhmrtLines,
  stations as tyhmrtStations,
} from '../data/tyhmrt'

import {
  lines as tyhmrtFutureLines,
  stations as tyhmrtFutureStations,
} from '../data/tyhmrt-future'

import {
  lines as khcmrtLines,
  stations as khcmrtStations,
} from '../data/khcmrt'

import {
  lines as tccmrtLines,
  stations as tccmrtStations,
} from '../data/tccmrt'

import {
  lines as tccmrtFutureLines,
  stations as tccmrtFutureStations,
} from '../data/tccmrt-future'

export default function SearchTab(props) {
  //const [tabkey, setTabkey] = useState('縣市區域')

  // const [townshipOrStation, setTownshipOrStation] = useState('')
  // const [countryOrLine, setCountryOrLine] = useState('')
  // const [line, setLine] = useState(-1)
  // const [station, setStation] = useState(-1)

  // const props = { line, setLine, station, setStation }
  //const keyChange = (eventKey, key) => eventKey === key
  const {
    townshipOrStation,
    setTownshipOrStation,
    countryOrLine,
    setCountryOrLine,
    area,
    setArea,
    setStreet,
    setSearchType,
    country,
    setCountry,
    township,
    setTownship,
    postcode,
    setPostcode,
    line,
    setLine,
    station,
    setStation,
    setVariant,
    variant,
  } = props

  useEffect(() => {
    //console.log(townshipOrStation)
  }, [townshipOrStation])

  useEffect(() => {
    //console.log(area, countryOrLine)
  }, [countryOrLine, area])

  // useEffect(() => {
  //   setArea(tabkey)
  // }, [tabkey])

  const resetData = () => {
    setTownship(-1)
    setCountry(-1)
    setTownshipOrStation(null)
    setCountryOrLine('')
    setStreet('')
    setSearchType(0)
    setLine(-1)
    setStation(-1)
    setVariant('line')
  }

  return (
    <>
      <Tabs
        id="controlled-tab"
        activeKey={area}
        onSelect={(k) => {
          setArea(k)
          if (k !== area) resetData()
        }}
      >
        <Tab eventKey="縣市區域" title="縣市區域">
          <TabTownship
            keyChange={area !== '縣市區域'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            townshipOrStation={townshipOrStation}
            countryOrLine={countryOrLine}
            country={country}
            setCountry={setCountry}
            township={township}
            setTownship={setTownship}
            postcode={postcode}
            setPostcode={setPostcode}
            area={area}
          />
        </Tab>
        <Tab eventKey="台北捷運" title="台北捷運">
          <TabMrt
            lines={tpemrtLines}
            stations={tpemrtStations}
            keyChange={area !== '台北捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="台北捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
        <Tab eventKey="台北未來捷運" title="台北未來捷運">
          <TabMrt
            lines={tpemrtFutureLines}
            stations={tpemrtFutureStations}
            keyChange={area !== '台北未來捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="台北未來捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
        <Tab eventKey="桃園捷運" title="桃園捷運">
          <TabMrt
            lines={tyhmrtLines}
            stations={tyhmrtStations}
            keyChange={area !== '桃園捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="桃園捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
        <Tab eventKey="桃園未來捷運" title="桃園未來捷運">
          <TabMrt
            lines={tyhmrtFutureLines}
            stations={tyhmrtFutureStations}
            keyChange={area !== '桃園未來捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="桃園未來捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
        <Tab eventKey="台中捷運" title="台中捷運">
          <TabMrt
            lines={tccmrtLines}
            stations={tccmrtStations}
            keyChange={area !== '台中捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="台中捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
        <Tab eventKey="台中未來捷運" title="台中未來捷運">
          <TabMrt
            lines={tccmrtFutureLines}
            stations={tccmrtFutureStations}
            keyChange={area !== '台中未來捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="台中未來捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
        <Tab eventKey="高雄捷運" title="高雄捷運">
          <TabMrt
            lines={khcmrtLines}
            stations={khcmrtStations}
            keyChange={area !== '高雄捷運'}
            setTownshipOrStation={setTownshipOrStation}
            setCountryOrLine={setCountryOrLine}
            setLine={setLine}
            line={line}
            setStation={setStation}
            station={station}
            area={area}
            myArea="高雄捷運"
            variant={variant}
            setVariant={setVariant}
          />
        </Tab>
      </Tabs>
    </>
  )
}
