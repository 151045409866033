// tpemrt-v1: #c48c31
// tpemrt-v2: #e3002c
// tpemrt-v3: #f8b61c
// tpemrt-v4: #008659
// tpemrt-v5: #0070bd
// tpemrt-v6: #8246AF
// tpemrt-v7: #FFDB00
// tpemrt-v8: #e5554f

// prettier-ignore

export const lines = [
         { name: '文山內湖線', variant: 'tpemrt-v1' },
         { name: '淡水信義線－淡水線', variant: 'tpemrt-v2' },
         { name: '淡水信義線－信義線', variant: 'tpemrt-v2' },
         { name: '新蘆中和線－新莊線', variant: 'tpemrt-v3' },
         { name: '新蘆中和線－蘆洲線', variant: 'tpemrt-v3' },
         { name: '新蘆中和線－中和線', variant: 'tpemrt-v3' },
         { name: '松山新店線－松山線', variant: 'tpemrt-v4' },
         { name: '松山新店線－新店線', variant: 'tpemrt-v4' },
         { name: '板南線', variant: 'tpemrt-v5' },
         { name: '機場線', variant: 'tpemrt-v6' },
         { name: '環狀線－西環段', variant: 'tpemrt-v7' },
         { name: '淡海輕軌綠山線', variant: 'tpemrt-v8' },
         { name: '淡海輕軌藍海線第一期', variant: 'tpemrt-v8' },
       ]

export const stations = [
         [
           {
             name: '動物園 BR01',
             address: '臺北市文山區新光路2段32號',
             lat: 24.9980685,
             lng: 121.57948,
           },
           {
             name: '木柵 BR02',
             address: '臺北市文山區木柵路4段135號',
             lat: 24.998241,
             lng: 121.5731452,
           },
           {
             name: '萬芳社區 BR03',
             address: '臺北市文山區萬芳路60號',
             lat: 24.998351,
             lng: 121.5685001,
           },
           {
             name: '萬芳醫院 BR04',
             address: '臺北市文山區興隆路3段113號',
             lat: 24.9993857,
             lng: 121.5581518,
           },
           {
             name: '辛亥 BR05',
             address: '臺北市文山區辛亥路4段128號',
             lat: 25.0054756,
             lng: 121.5565917,
           },
           {
             name: '麟光 BR06',
             address: '臺北市大安區和平東路3段410號',
             lat: 25.018567,
             lng: 121.5588356,
           },
           {
             name: '六張犁 BR07',
             address: '臺北市大安區和平東路3段168號',
             lat: 25.0238135,
             lng: 121.5531506,
           },
           {
             name: '科技大樓 BR08',
             address: '臺北市大安區復興南路2段235號',
             lat: 25.0261461,
             lng: 121.5436466,
           },
           {
             name: '大安 BR09',
             address: '臺北市大安區信義路4段2號',
             lat: 25.033155,
             lng: 121.5438217,
           },
           {
             name: '忠孝復興 BR10',
             address: '臺北市大安區忠孝東路4段47號',
             lat: 25.0417843,
             lng: 121.5448626,
           },
           {
             name: '南京東路 BR11',
             address: '臺北市松山區南京東路3段253號',
             lat: 25.0520565,
             lng: 121.5445172,
           },
           {
             name: '中山國中 BR012',
             address: '臺北市中山區復興北路376號',
             lat: 25.0608158,
             lng: 121.5439767,
           },
           {
             name: '松山機場 BR13',
             address: '臺北市松山區敦化北路338號',
             lat: 25.063718,
             lng: 121.549643,
           },
           {
             name: '大直 BR14',
             address: '臺北市中山區北安路534之1號',
             lat: 25.079623,
             lng: 121.546816,
           },
           {
             name: '劍南路 BR15',
             address: '臺北市中山區北安路798號',
             lat: 25.084709,
             lng: 121.555612,
           },
           {
             name: '西湖 BR16',
             address: '臺北市內湖區內湖路1段256號',
             lat: 25.0820029,
             lng: 121.5671357,
           },
           {
             name: '港墘 BR17',
             address: '臺北市內湖區內湖路1段663號',
             lat: 25.0801772,
             lng: 121.5756865,
           },
           {
             name: '文德 BR18',
             address: '臺北市內湖區文德路214號',
             lat: 25.0783742,
             lng: 121.5845868,
           },
           {
             name: '內湖 BR19',
             address: '臺北市內湖區成功路4段186號',
             lat: 25.0838996,
             lng: 121.5942576,
           },
           {
             name: '大湖公園 BR20',
             address: '臺北市內湖區成功路5段11號',
             lat: 25.0833383,
             lng: 121.6026639,
           },
           {
             name: '葫洲 BR21',
             address: '臺北市內湖區康寧路3段16號',
             lat: 25.0725219,
             lng: 121.6072978,
           },
           {
             name: '東湖 BR22',
             address: '臺北市內湖區康寧路3段235號',
             lat: 25.0671258,
             lng: 121.6117183,
           },
           {
             name: '南港軟體園區 BR23',
             address: '臺北市南港區經貿二路183號',
             lat: 25.0600356,
             lng: 121.6160813,
           },
           {
             name: '南港展覽館 BR24',
             address: '臺北市南港區南港路1段32號',
             lat: 25.0553077,
             lng: 121.617299,
           },
         ],
         [
           {
             name: '淡水 R28',
             address: '新北市淡水區中正路1號',
             lat: 25.1748783,
             lng: 121.4327391,
           },
           {
             name: '紅樹林 R27',
             address: '新北市淡水區中正東路2段68號',
             lat: 25.1547244,
             lng: 121.4589193,
           },
           {
             name: '竹圍 R26',
             address: '新北市淡水區民權路50號',
             lat: 25.136066,
             lng: 121.45969,
           },
           {
             name: '關渡 R25',
             address: '臺北市北投區大度路3段296巷51號',
             lat: 25.1253502,
             lng: 121.4671526,
           },
           {
             name: '忠義 R24',
             address: '臺北市北投區中央北路4段301號',
             lat: 25.1310975,
             lng: 121.4732346,
           },
           {
             name: '復興崗 R23',
             address: '臺北市北投區中央北路3段53巷10號',
             lat: 25.137636,
             lng: 121.485432,
           },
           {
             name: '新北投 R22A',
             address: '臺北市北投區大業路700號',
             lat: 25.1369329,
             lng: 121.50253,
           },
           {
             name: '北投 R22',
             address: '臺北市北投區光明路1號',
             lat: 25.131841,
             lng: 121.498633,
           },
           {
             name: '奇岩 R21',
             address: '臺北市北投區三合街2段489號',
             lat: 25.1260169,
             lng: 121.5010117,
           },
           {
             name: '唭哩岸 R20',
             address: '臺北市北投區東華街2段301號',
             lat: 25.1208763,
             lng: 121.5062194,
           },
           {
             name: '石牌 R19',
             address: '臺北市北投區石牌路1段200號',
             lat: 25.1140573,
             lng: 121.5127398,
           },
           {
             name: '明德 R18',
             address: '臺北市北投區明德路95號',
             lat: 25.109721,
             lng: 121.518848,
           },
           {
             name: '芝山 R17',
             address: '臺北市士林區福國路70號',
             lat: 25.1030599,
             lng: 121.522514,
           },
           {
             name: '士林 R16',
             address: '臺北市士林區福德路1號',
             lat: 25.093535,
             lng: 121.52623,
           },
           {
             name: '劍潭 R15',
             address: '臺北市士林區中山北路5段65號',
             lat: 25.084873,
             lng: 121.525078,
           },
           {
             name: '圓山 R14',
             address: '臺北市大同區酒泉街9之1號',
             lat: 25.0707797,
             lng: 121.5207878,
           },
           {
             name: '民權西路 R13',
             address: '臺北市大同區民權西路72號',
             lat: 25.0626118,
             lng: 121.5195281,
           },
           {
             name: '雙連 R12',
             address: '臺北市大同區民生西路47號',
             lat: 25.0578694,
             lng: 121.5206471,
           },
           {
             name: '中山 R11',
             address: '臺北市中山區南京西路16號',
             lat: 25.0521912,
             lng: 121.5221371,
           },
           {
             name: '台北車站 R10',
             address: '臺北市中正區忠孝西路1段49號',
             lat: 25.0463846,
             lng: 121.5178642,
           },
           {
             name: '台大醫院 R09',
             address: '臺北市中正區公園路52號',
             lat: 25.041003,
             lng: 121.515715,
           },
           {
             name: '中正紀念堂 R08',
             address: '臺北市中正區羅斯福路1段8之1號',
             lat: 25.0322107,
             lng: 121.5183466,
           },
         ],
         [
           {
             name: '中正紀念堂 R08',
             address: '10074臺北市中正區羅斯福路1段8之1號',
             lat: 25.0322107,
             lng: 121.5183466,
           },
           {
             name: '東門 R07',
             address: '臺北市大安區信義路2段166號',
             lat: 25.0336009,
             lng: 121.5292686,
           },
           {
             name: '大安森林公園 R06',
             address: '臺北市大安區信義路3段100號',
             lat: 25.0333339,
             lng: 121.5362106,
           },
           {
             name: '大安 R05',
             address: '臺北市大安區信義路4段2號',
             lat: 25.033155,
             lng: 121.5438217,
           },
           {
             name: '信義安和 R04',
             address: '臺北市大安區信義路4段212之1號',
             lat: 25.0330772,
             lng: 121.552085,
           },
           {
             name: '台北101/世貿 R03',
             address: '臺北市信義區信義路5段20號',
             lat: 25.0327792,
             lng: 121.5636894,
           },
           {
             name: '象山 R02',
             address: '臺北市信義區信義路5段152號',
             lat: 25.0326708,
             lng: 121.5695364,
           },
         ],
         [
           {
             name: '迴龍 O21',
             address: '新北市新莊區中正路758號',
             lat: 25.0218242,
             lng: 121.4112655,
           },
           {
             name: '丹鳳 O20',
             address: '新北市新莊區中正路624之1號',
             lat: 25.0162429,
             lng: 121.410059,
           },
           {
             name: '輔大站 O19',
             address: '新北市新莊區中正路510之1號',
             lat: 25.0336491,
             lng: 121.433255,
           },
           {
             name: '新莊 O18',
             address: '新北市新莊區中正路138號',
             lat: 25.0028275,
             lng: 121.4241944,
           },
           {
             name: '頭前庄 O17',
             address: '新北市新莊區思源路18號',
             lat: 25.0397749,
             lng: 121.4607719,
           },
           {
             name: '先嗇宮 O16',
             address: '新北市三重區重新路5段515號',
             lat: 25.0465799,
             lng: 121.47129,
           },
           {
             name: '三重 O15',
             address: '新北市三重區捷運路36號',
             lat: 25.05521,
             lng: 121.4832314,
           },
           {
             name: '菜寮 O14',
             address: '新北市三重區重新路3段150號',
             lat: 25.0594539,
             lng: 121.491432,
           },
           {
             name: '台北橋 O13',
             address: '新北市三重區重新路1段108號',
             lat: 25.063048,
             lng: 121.500932,
           },
           {
             name: '大橋頭 O12',
             address: '臺北市大同區民權西路223號',
             lat: 25.0632032,
             lng: 121.5122844,
           },
         ],
         [
           {
             name: '蘆洲 O54',
             address: '新北市蘆洲區三民路386號',
             lat: 25.0915536,
             lng: 121.4644714,
           },
           {
             name: '三民高中 O53',
             address: '新北市蘆洲區三民路105號',
             lat: 25.0855962,
             lng: 121.4729956,
           },
           {
             name: '徐匯中學 O52',
             address: '新北市蘆洲區中山一路3號',
             lat: 25.08035,
             lng: 121.479925,
           },
           {
             name: '三和國中 O51',
             address: '新北市三重區三和路4段107號',
             lat: 25.0773397,
             lng: 121.4874946,
           },
           {
             name: '三重國小 O50',
             address: '新北市三重區三和路3段5號',
             lat: 25.0706925,
             lng: 121.4967141,
           },
           {
             name: '大橋頭 O12',
             address: '臺北市大同區民權西路223號',
             lat: 25.0632032,
             lng: 121.5122844,
           },
         ],
         [
           {
             name: '大橋頭 O12',
             address: '臺北市大同區民權西路223號',
             lat: 25.0632032,
             lng: 121.5122844,
           },
           {
             name: '民權西路 O11',
             address: '臺北市大同區民權西路72號',
             lat: 25.0626118,
             lng: 121.5195281,
           },
           {
             name: '中山國小 O10',
             address: '臺北市中山區民權東路1段71號',
             lat: 25.0620999,
             lng: 121.5378624,
           },
           {
             name: '行天宮 O09',
             address: '臺北市中山區松江路316號',
             lat: 25.0604614,
             lng: 121.53282,
           },
           {
             name: '松江南京 O08',
             address: '臺北市中山區松江路126號',
             lat: 25.0526933,
             lng: 121.5327187,
           },
           {
             name: '忠孝新生 O07',
             address: '臺北市大安區新生南路1段67號',
             lat: 25.0420248,
             lng: 121.5331036,
           },
           {
             name: '東門 O06',
             address: '臺北市大安區信義路2段166號',
             lat: 25.0336009,
             lng: 121.5292686,
           },
           {
             name: '古亭 O05',
             address: '臺北市中正區羅斯福路2段164之1號',
             lat: 25.0255152,
             lng: 121.5233395,
           },
           {
             name: '頂溪 O04',
             address: '新北市永和區永和路2段168號',
             lat: 25.012836,
             lng: 121.5154203,
           },
           {
             name: '永安市場 O03',
             address: '新北市中和區中和路388號',
             lat: 25.0023426,
             lng: 121.5109643,
           },
           {
             name: '景安 O02',
             address: '新北市中和區景平路486號',
             lat: 24.9938396,
             lng: 121.504825,
           },
           {
             name: '南勢角 O01',
             address: '新北市中和區捷運路6號',
             lat: 24.9899168,
             lng: 121.5090114,
           },
         ],
         [
           {
             name: '西門 G12',
             address: '臺北市中正區寶慶路32之1號',
             lat: 25.0415012,
             lng: 121.5091114,
           },
           {
             name: '北門 G13',
             address: '25.050011,121.510438',
             lat: 25.04993,
             lng: 121.510367,
           },
           {
             name: '中山 G14',
             address: '臺北市中山區南京西路16號',
             lat: 25.0521912,
             lng: 121.5221371,
           },
           {
             name: '松江南京 G15',
             address: '臺北市中山區松江路126號',
             lat: 25.0526933,
             lng: 121.5327187,
           },
           {
             name: '南京東路 G16',
             address: '臺北市松山區南京東路3段253號',
             lat: 25.0520565,
             lng: 121.5445172,
           },
           {
             name: '台北小巨蛋站 G17',
             address: '25.051548,121.552608',
             lat: 25.051668,
             lng: 121.551879,
           },
           {
             name: '南京三民 G18',
             address: '25.051436,121.563742',
             lat: 25.051429,
             lng: 121.563858,
           },
           {
             name: '松山 G19',
             address: '25.050187,121.577498',
             lat: 25.0501614,
             lng: 121.577507,
           },
         ],
         [
           {
             name: '小南門 G11',
             address: '臺北市中正區愛國西路22號',
             lat: 25.0352482,
             lng: 121.5114384,
           },
           {
             name: '中正紀念堂 G10',
             address: '臺北市中正區羅斯福路1段8之1號',
             lat: 25.0322107,
             lng: 121.5183466,
           },
           {
             name: '古亭 G09',
             address: '臺北市中正區羅斯福路2段164之1號',
             lat: 25.0255152,
             lng: 121.5233395,
           },
           {
             name: '台電大樓 G08',
             address: '臺北市中正區羅斯福路3段126之5號',
             lat: 25.0205742,
             lng: 121.5279607,
           },
           {
             name: '公館 G07',
             address: '臺北市中正區羅斯福路4段74號',
             lat: 25.0144012,
             lng: 121.5344194,
           },
           {
             name: '萬隆 G06',
             address: '臺北市文山區羅斯福路5段214號',
             lat: 25.0025772,
             lng: 121.5385812,
           },
           {
             name: '景美 G05',
             address: '臺北市文山區羅斯福路6段393號',
             lat: 24.9921478,
             lng: 121.54074,
           },
           {
             name: '大坪林 G04',
             address: '新北市新店區北新路3段190號',
             lat: 24.9831,
             lng: 121.5418,
           },
           {
             name: '七張 G03',
             address: '新北市新店區北新路2段150號',
             lat: 24.9751965,
             lng: 121.5431453,
           },
           {
             name: '小碧潭 G03A',
             address: '新北市新店區中央路151號4樓',
             lat: 24.9732341,
             lng: 121.5299971,
           },
           {
             name: '新店區公所 G02',
             address: '新北市新店區北新路1段295號',
             lat: 24.967498,
             lng: 121.541192,
           },
           {
             name: '新店 G01',
             address: '新北市新店區北宜路1段2號',
             lat: 24.9578555,
             lng: 121.5375837,
           },
         ],
         [
           {
             name: '南港展覽館 BL23',
             address: '臺北市南港區南港路1段32號',
             lat: 25.0553077,
             lng: 121.617299,
           },
           {
             name: '南港 BL22',
             address: '臺北市南港區忠孝東路7段380號',
             lat: 25.0518018,
             lng: 121.6060457,
           },
           {
             name: '昆陽 BL21',
             address: '臺北市南港區忠孝東路6段451號',
             lat: 25.0506011,
             lng: 121.5938363,
           },
           {
             name: '後山埤 BL20',
             address: '臺北市南港區忠孝東路6段2號',
             lat: 25.0452586,
             lng: 121.5829712,
           },
           {
             name: '永春 BL19',
             address: '臺北市信義區忠孝東路5段455號',
             lat: 25.041007,
             lng: 121.576419,
           },
           {
             name: '市政府 BL18',
             address: '臺北市信義區忠孝東路5段2號',
             lat: 25.0409716,
             lng: 121.5651397,
           },
           {
             name: '國父紀念館 BL17',
             address: '臺北市信義區忠孝東路4段400號',
             lat: 25.0411779,
             lng: 121.5580209,
           },
           {
             name: '忠孝敦化 BL16',
             address: '臺北市大安區忠孝東路4段182號',
             lat: 25.0413713,
             lng: 121.5516086,
           },
           {
             name: '忠孝復興 BL15',
             address: '臺北市大安區忠孝東路4段47號',
             lat: 25.0417843,
             lng: 121.5448626,
           },
           {
             name: '忠孝新生 BL14',
             address: '臺北市大安區新生南路1段67號',
             lat: 25.0420248,
             lng: 121.5331036,
           },
           {
             name: '善導寺 BL13',
             address: '臺北市中正區忠孝東路1段58號',
             lat: 25.0424604,
             lng: 121.5356504,
           },
           {
             name: '台北車站 BL12',
             address: '臺北市中正區忠孝西路1段49號',
             lat: 25.0463846,
             lng: 121.5178642,
           },
           {
             name: '西門 BL11',
             address: '臺北市中正區寶慶路32之1號',
             lat: 25.0415012,
             lng: 121.5091114,
           },
           {
             name: '龍山寺 BL10',
             address: '臺北市萬華區西園路1段153號',
             lat: 25.0354487,
             lng: 121.4995792,
           },
           {
             name: '江子翠 BL09',
             address: '新北市板橋區文化路2段296號',
             lat: 25.0296685,
             lng: 121.4723603,
           },
           {
             name: '新埔 BL08',
             address: '新北市板橋區民生路3段2號',
             lat: 25.0242359,
             lng: 121.468174,
           },
           {
             name: '板橋 BL07',
             address: '新北市板橋區站前路5號',
             lat: 25.014579,
             lng: 121.4625101,
           },
           {
             name: '府中 BL06',
             address: '新北市板橋區縣民大道1段193號',
             lat: 25.008351,
             lng: 121.4590365,
           },
           {
             name: '亞東醫院 BL05',
             address: '新北市板橋區南雅南路2段17號',
             lat: 24.9974796,
             lng: 121.4523829,
           },
           {
             name: '海山 BL04',
             address: '新北市土城區樂利街16號B2',
             lat: 24.9867384,
             lng: 121.4497775,
           },
           {
             name: '土城 BL03',
             address: '新北市土城區金城路1段105號',
             lat: 24.972736,
             lng: 121.4436896,
           },
           {
             name: '永寧 BL02',
             address: '新北市土城區中央路3段105號',
             lat: 24.9667561,
             lng: 121.4364985,
           },
           {
             name: '頂埔 BL01',
             address: '新北市土城區中央路4段51之6號',
             lat: 24.9598747,
             lng: 121.4211303,
           },
         ],
         [
           {
             name: '台北車站 A1',
             address: '臺北市中正區忠孝西路1段49號',
             lat: 25.0463846,
             lng: 121.5178642,
           },
           {
             name: '三重 A2',
             address: '新北市三重區捷運路36號',
             lat: 25.05521,
             lng: 121.4832314,
           },
           {
             name: '新北產業園區 A3',
             address: '新北市新莊區五工路37號',
             lat: 25.0617119,
             lng: 121.4588938,
           },
           {
             name: '新莊副都心 A4',
             address: '新北市新莊區新北大道四段188號',
             lat: 25.0593624,
             lng: 121.4461393,
           },
           {
             name: '泰山 A5',
             address: '新北市泰山區新北大道四段431號',
             lat: 25.0530061,
             lng: 121.43969,
           },
           {
             name: '泰山貴和 A6',
             address: '新北市泰山區新北大道六段460號 ',
             lat: 25.0334108,
             lng: 121.4224296,
           },
           {
             name: '體育大學 A7',
             address: '桃園市龜山區文化一路688號',
             lat: 25.041246,
             lng: 121.385434,
           },
           {
             name: '長庚醫院 A8',
             address: '桃園市龜山區文化一路6號',
             lat: 25.0609657,
             lng: 121.3704421,
           },
           {
             name: '林口 A9',
             address: '新北市林口區八德路290號',
             lat: 25.0659623,
             lng: 121.3614722,
           },
         ],
         [
           {
             name: '大坪林 Y07',
             address: '新北市新店區北新路3段190號',
             lat: 24.9831,
             lng: 121.5418,
           },
           {
             name: '十四張 Y08',
             address: '',
             lat: 24.982928,
             lng: 121.533807,
           },
           { name: '秀朗橋 Y09', address: '', lat: 24.990475, lng: 121.524174 },
           {
             name: '景平 Y10',
             address: '新北市中和區景平路123號',
             lat: 24.9917562,
             lng: 121.5160554,
           },
           {
             name: '景安 Y11',
             address: '新北市中和區景平路486號',
             lat: 24.9938396,
             lng: 121.504825,
           },
           { name: '中和站 Y12', address: '', lat: 25.001835, lng: 121.49662 },
           { name: '橋和 Y13', address: '', lat: 25.004402, lng: 121.490226 },
           { name: '中原 Y14', address: '', lat: 25.008246, lng: 121.484317 },
           { name: '板新 Y15', address: '', lat: 25.014919, lng: 121.471926 },
           {
             name: '板橋 Y16',
             address: '新北市板橋區站前路5號',
             lat: 25.014579,
             lng: 121.4625101,
           },
           {
             name: '新埔民生 Y17',
             address: '新北市板橋區民生路3段2號',
             lat: 25.0242359,
             lng: 121.468174,
           },
           {
             name: '頭前庄 Y18',
             address: '新北市新莊區思源路18號',
             lat: 25.0397749,
             lng: 121.4607719,
           },
           { name: '幸福 Y19', address: '', lat: 25.049488, lng: 121.459739 },
           {
             name: '新北產業園區 Y20',
             address: '新北市新莊區五工路37號',
             lat: 25.0617119,
             lng: 121.4588938,
           },
         ],
         [
           {
             name: '紅樹林 V01',
             address: '新北市淡水區中正東路2段68號',
             lat: 25.1547244,
             lng: 121.4589193,
           },
           { name: '竿蓁林 V02', address: '', lat: 25.161837, lng: 121.456405 },
           {
             name: '淡金鄧公 V03',
             address: '',
             lat: 25.169328,
             lng: 121.46083,
           },
           {
             name: '淡江大學 V04',
             address: '',
             lat: 25.175715,
             lng: 121.45677,
           },
           {
             name: '淡金北新 V05',
             address: '',
             lat: 25.180118,
             lng: 121.453532,
           },
           {
             name: '新市一路 V06',
             address: '',
             lat: 25.183826,
             lng: 121.448259,
           },
           {
             name: '淡水行政中心 V07',
             address: '',
             lat: 25.189221,
             lng: 121.443371,
           },
           {
             name: '濱海義山 V08',
             address: '',
             lat: 25.189655,
             lng: 121.437442,
           },
           {
             name: '濱海沙崙 V09',
             address: '',
             lat: 25.190743,
             lng: 121.431644,
           },
           {
             name: '淡海新市鎮 V10',
             address: '',
             lat: 25.194503,
             lng: 121.432259,
           },
           { name: '崁頂 V11', address: '', lat: 25.200969, lng: 121.434614 },
         ],
          [
            {
              name: '淡水漁人碼頭 V26',
              address: '',
              lat: 25.181644,
              lng: 121.418385,
            },
            { name: '沙崙 V27', address: '', lat: 25.187101, lng: 121.416883 },
            {
              name: '台北海洋學院 V28',
              address: '',
              lat: 25.191287,
              lng: 121.426705,
            },
          ],
       ]
