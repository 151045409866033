import React, { useState, useRef, useEffect } from 'react'

import { Row, Col, Button } from 'react-bootstrap'
import { BsSearch } from 'react-icons/bs'
import { FaRegHandPointDown } from 'react-icons/fa'

import Picker from './react-month-picker/month-picker'
import './react-month-picker/scss/pack-month-picker.scss'
import './MonthRange.scss'
import MonthBox from './MonthBox'

function MonthRange(props) {
  const { setBtype, setKeytypeGlobal } = props
  const [range, setRange] = useState({
    from: { year: 2023, month: 1 },
    to: { year: 2023, month: 12 },
  })

  const pickRangeRef = useRef()

  const pickerLang = {
    months: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月',
    ],
    from: '從',
    to: '到',
  }

  const makeText = (m) => {
    if (m && m.year && m.month)
      return m.year + '年' + pickerLang.months[m.month - 1]
    return '?'
  }

  const handleRangeChange2 = (value, text, listIndex) => {
    //
  }
  const handleRangeDissmis2 = (value) => {
    setRange(value)
  }
  const handleClickMonthBox2 = (e) => {
    pickRangeRef.current.show()
  }

  // update if range change
  const handleRangeQuery = () => {
    if (range.from.year && range.from.month && range.to.year && range.to.month)
      props.setRangeQuery(range)
    else alert('please select corrent from and to year/month!')

    // change tab
    setKeytypeGlobal('oneyear')
    setBtype(0)
  }

  return (
    <div className="mb-3">
      <label>
        <b>時間區間查詢 (預設為近一年)：</b>
        <div className="small">
          點按以下區域可選擇其他年月
          <FaRegHandPointDown color="#333" />
        </div>
      </label>
      <Row>
        <Col xs={12} sm={10}>
          <div className="edit">
            <Picker
              ref={pickRangeRef}
              years={{
                min: { year: 2012, month: 7 },
                max: { year: 2022, month: 3 },
              }}
              value={range}
              lang={pickerLang}
              theme="dark"
              onChange={handleRangeChange2}
              onDismiss={handleRangeDissmis2}
            >
              <MonthBox
                value={makeText(range.from) + ' ~ ' + makeText(range.to)}
                onClick={handleClickMonthBox2}
              />
            </Picker>
          </div>
        </Col>
        <Col xs={12} sm={2}>
          <Button
            type="button"
            variant="danger"
            style={{ minWidth: '100%' }}
            className="mb-2"
            onClick={handleRangeQuery}
          >
            <BsSearch /> 查詢
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default MonthRange
