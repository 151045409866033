// tyhmrt-future-v1: #8246AF
// v2: #ea0437
// v3: #62A033

export const lines = [
         //v1
         {
           name: '機場線延伸段',
           variant: 'tyhmrt-future-v1',
           info: '興建中，A22站預計2022年底通車，A23預計2028年通車',
         },
         //v2
         {
           name: '紅線',
           variant: 'tyhmrt-future-v2',
           info: '已完成環評，待行政院核定，部份路段已完成臨時軌鋪設',
         },
         //v3
         {
           name: '綠線',
           variant: 'tyhmrt-future-v3',
           info:
             '預定2026年通車。至於捷運綠線延伸中壢＆捷運三鶯延伸八德，尚未核定，至2020年1月尚在可行性研究階段。',
         },
       ]

export const stations = [
         [
           {
             name: '老街溪/永興公園 A22',
             address: '',
             lat: 24.958437,
             lng: 121.219386,
           },
           { name: '中壢 A23', address: '', lat: 24.953028, lng: 121.22578 },
         ],
         [
           { name: '平鎮', address: '', lat: 24.943505, lng: 121.207265 },
           { name: '中壢', address: '', lat: 24.953028, lng: 121.22578 },
           { name: '中原', address: '', lat: 24.960499, lng: 121.24039 },
           {
             name: '內壢',
             address: '桃園縣中壢市中和路139號',
             lat: 24.9534545,
             lng: 121.2257365,
           },
           { name: '永豐', address: '', lat: 24.977558, lng: 121.270091 },
           { name: '中路', address: '', lat: 24.984171, lng: 121.28966 },
           {
             name: '桃園',
             address: '桃園縣桃園市中正路1號',
             lat: 24.9892245,
             lng: 121.3128926,
           },
           { name: '鳳鳴', address: '', lat: 24.976724, lng: 121.33257 },
         ],
         [
           { name: '八德 G01', address: '', lat: 24.929019, lng: 121.281116 },
           { name: '建國 G02', address: '', lat: 24.938047, lng: 121.2882 },
           { name: '麻園 G03', address: '', lat: 24.942649, lng: 121.297474 },
           { name: '大湳 G04', address: '', lat: 24.95727, lng: 121.299316 },
           {
             name: '忠勇西街 G05',
             address: '',
             lat: 24.968747,
             lng: 121.301315,
           },
           {
             name: '陽明公園 G06',
             address: '',
             lat: 24.979999,
             lng: 121.306025,
           },
           { name: '桃園 G07', address: '', lat: null, lng: null },
           {
             name: '永和市場 G08',
             address: '',
             lat: 24.996551,
             lng: 121.309952,
           },
           { name: '民光 G09', address: '', lat: 25.003522, lng: 121.305553 },
           {
             name: '大興西路 G10',
             address: '',
             lat: 25.012274,
             lng: 121.300262,
           },
           {
             name: '展演中心 G11',
             address: '',
             lat: 25.01834,
             lng: 121.297528,
           },
           {
             name: '南崁轉運站 G12',
             address: '',
             lat: 25.027828,
             lng: 121.294212,
           },
           { name: '南竹 G13', address: '', lat: 25.038648, lng: 121.282762 },
           {
             name: '蘆竹工業區 G13a',
             address: '',
             lat: 25.05213,
             lng: 121.275752,
           },
           { name: '長興 G14', address: '', lat: 25.062626, lng: 121.267237 },
           {
             name: 'G15 航空城1站',
             address: '',
             lat: 25.067174,
             lng: 121.255932,
           },
           {
             name: '航空城2站 G16',
             address: '',
             lat: 25.059322,
             lng: 121.246748,
           },
           {
             name: '航空城3站 G17',
             address: '',
             lat: 25.048747,
             lng: 121.235418,
           },
           {
             name: '橫山 G18',
             address: '桃園市大園區大竹南路1180號',
             lat: 25.0420697,
             lng: 121.2391829,
           },
           { name: '菓林 G31', address: '', lat: 25.079769, lng: 121.258764 },
           { name: '坑口 G32', address: '', lat: 25.086362, lng: 121.266392 },
         ],
       ]
