// tccmrt-future-v1: #8EC31C
// tccmrt-future-v2: #ea0437

export const lines = [
         //v1
        //  {
        //    name: '綠線',
        //    variant: 'tccmrt-future-v1',
        //    info: '預計2020年通車',
        //  },
         // v2
         {
           name: '崇德豐原線',
           variant: 'tccmrt-future-v2',
           info: '規劃中',
         },
       ]

export const stations = [
        //  [
        //    {
        //      name: '北屯總站 103a',
        //      address: '',
        //      lat: 24.189154,
        //      lng: 120.708633,
        //    },
        //    { name: '舊社 103', address: '', lat: 24.18242, lng: 120.707184 },
        //    { name: '松竹 104', address: '', lat: 24.180971, lng: 120.70153 },
        //    {
        //      name: '四維國小 105',
        //      address: '',
        //      lat: 24.17119,
        //      lng: 120.693314,
        //    },
        //    {
        //      name: '崇德文心 106',
        //      address: '',
        //      lat: 24.172387,
        //      lng: 120.684664,
        //    },
        //    {
        //      name: '中清文心 107',
        //      address: '',
        //      lat: 24.173758,
        //      lng: 120.670679,
        //    },
        //    {
        //      name: '文華高中 108',
        //      address: '',
        //      lat: 24.171566,
        //      lng: 120.660385,
        //    },
        //    {
        //      name: '文心櫻花 109',
        //      address: '',
        //      lat: 24.167587,
        //      lng: 120.653862,
        //    },
        //    {
        //      name: '臺中市政府站 110',
        //      address: '',
        //      lat: 24.162048,
        //      lng: 120.649001,
        //    },
        //    {
        //      name: '文心大業 111',
        //      address: '',
        //      lat: 24.153321,
        //      lng: 120.646781,
        //    },
        //    {
        //      name: '文心森林公園站 112',
        //      address: '',
        //      lat: 24.145489,
        //      lng: 120.646711,
        //    },
        //    { name: '南屯 113', address: '', lat: 24.140364, lng: 120.646733 },
        //    {
        //      name: '豐樂公園站 114',
        //      address: '',
        //      lat: 24.1321,
        //      lng: 120.646352,
        //    },
        //    { name: '大慶 115', address: '', lat: 24.119038, lng: 120.647318 },
        //    {
        //      name: '九張犁站 116',
        //      address: '',
        //      lat: 24.114571,
        //      lng: 120.640163,
        //    },
        //    { name: '九德 117', address: '', lat: 24.110834, lng: 120.634391 },
        //    { name: '烏日 118', address: '', lat: 24.109057, lng: 120.624852 },
        //    {
        //      name: '臺中高鐵站 119',
        //      address: '台中縣烏日鄉站區二路8號',
        //      lat: 24.1117337,
        //      lng: 120.6157348,
        //    },
        //  ],
         [
           {
             name: '豐原',
             address: '台中市豐原區中正路1號',
             lat: 24.254112,
             lng: 120.722917,
           },
           {
             name: '栗林(原豐南)',
             address: '',
             lat: 24.234681,
             lng: 120.710605,
           },
           {
             name: '潭子',
             address: '台中市潭子區中山路二段352號',
             lat: 24.2126188,
             lng: 120.7054928,
           },
           { name: '頭家厝', address: '', lat: 24.19535, lng: 120.703961 },
           { name: '松竹', address: '', lat: 24.180208, lng: 120.70197 },
           {
             name: '太原',
             address: '台中市北屯區東光路833號',
             lat: 24.16669,
             lng: 120.7000866,
           },
           { name: '精武', address: '', lat: 24.149038, lng: 120.697758 },
           {
             name: '台中車站',
             address: '台中市中區建國路172號',
             lat: 24.1368307,
             lng: 120.6848278,
           },
           { name: '五權', address: '', lat: 24.129392, lng: 120.666949 },
           { name: '大慶', address: '', lat: 24.119563, lng: 120.64873 },
           { name: '烏日', address: '', lat: 24.108743, lng: 120.622502 },
           {
             name: '新烏日(高鐵站)',
             address: '台中縣烏日鄉站區二路8號',
             lat: 24.1117337,
             lng: 120.6157348,
           },
           {
             name: '成功',
             address: '台中市烏日區中山路三段550號',
             lat: 24.1136912,
             lng: 120.590675,
           },
         ],
       ]
